<template>
    <div class="photography-body">
        <h1>Photography</h1>
    </div>
</template>

<script>
export default {
    name: 'MainPhotography',
    components: {

    }
}
</script>


<style scoped lang="sass">
.photography-body
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background: var(--color-background-primary)
  color: var(--color-text-primary)
  font-size: 2rem
  font-weight: bold
  text-transform: uppercase
  letter-spacing: 2px
  border-radius: 10px
  margin: 3px
</style>